<template>
  <page-container3>
    <div class="ant-pro-table">
      <div ref="searchEle" class="ant-pro-table-search">
        <search-render
          :defaultShowEdit="true"
          :form="searchConfig"
          :model="searchConfig.model"
          :options="searchConfigOptions"
          :resetFields="resetFields"
          :validateInfos="validateInfos"
          @search="search"
        />
      </div>
      <a-card ref="elRef" :body-style="{ padding: 0 }" class="cards">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">工时日历</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="刷新">
                  <reload-outlined @click="search" />
                </a-tooltip>
              </div>
              <div v-if="is_export_excel" class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="导出Excel">
                  <export-excel-modal
                    :column-list="dynamicColumnItems"
                    :columns="baseColumns"
                    :datasource="dataSource"
                    :filename="filename"
                  />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <s-table
          :autoRowHeight="true"
          :bordered="true"
          :columns="baseColumns"
          :customCell="customCell"
          :data-source="dataSource"
          :height="tableHeight.y"
          :ignoreCellKey="true"
          :indentSize="0"
          :loading="loading"
          :pagination="false"
          :row-height="30"
          :rowKey="'id'"
          :show-header="false"
          :size="'small'"
          :virtual="true"
          :wrapText="true"
          :xVirtual="false"
          sticky
          stripe
          summary-fixed
        >
          <template #headerCell="{ title }">
            <div style="width: 100%;text-align:center;">
              {{ title }}
            </div>
          </template>
          <template #bodyCell="{ record, column, text, index }">
            <template v-if="record.name === 'Name'">
              <div style="width: 100%;text-align:center;">
                <span style="font-weight: 500;color: #f0f2f5">{{ text }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'name'">
              <div style="width: 100%;text-align:center;">
                <span style="font-weight: 600;">{{ text }}</span>
              </div>
            </template>
            <template v-else-if="record.name === 'Total Hours' || (column.dataIndex === 'total' && text !== 'total') ">
              <div style="width: 100%;text-align:center;">
                <span style="font-weight: 600;color: #2686BE">{{ text }}</span>
              </div>
            </template>
            <template v-else>
              <div style="width: 100%;text-align:center;">
                {{ text }}
              </div>
            </template>
          </template>
        </s-table>
      </a-card>
    </div>
  </page-container3>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { ReloadOutlined } from '@ant-design/icons-vue';
import { debounce } from 'lodash-es';
import PageContainer3 from '@/components/base-layouts/page-container3/index.vue';
import { STable } from '@surely-vue/table';
import type { SearchConfig } from '@/components/page-model/typing';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { useForm } from 'ant-design-vue/es/form';
import ExportExcelModal from '@/components/info-modal/export-excel-modal/index.vue';
import moment from 'moment/moment';
import { useRoute } from 'vue-router';
import { getFullDate } from '@/utils/function';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    ExportExcelModal,
    SearchRender,
    STable,
    PageContainer3,
    ReloadOutlined,
  },
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const route = useRoute();
    const path_arr = route.path.split('/');
    const searchEle: any = ref();
    const activeKey: any = ref('产出产量');
    const filename: any = ref('');
    const loading: any = ref(false);
    const is_export_excel: any = ref(true);
    const tableHeight = reactive({ y: window.innerHeight - 215 });
    const onresize: any = debounce(() => {
      tableHeight.y = window.innerHeight - 215;
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });

    let search_obj: any = {};
    const searchConfig: SearchConfig = {
      settings: {
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'rangepicker',
          name: 'date_interval',
          label: '时间范围',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: ['开始时间', '结束时间'],
        },
      ],
      rules: {},
      model: reactive({
        org_id: current_org?.id,
        date_interval: [moment().subtract(8, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')],
      }),
    };

    const json = [
      {
        'week': {
          'name': 'Name',
          'total': '',
          '1': 'Tue',
          '2': 'Wed',
          '3': 'Thu',
          '4': 'Fri',
          '5': 'Sat',
          '6': 'Sun',
          '7': 'Mon',
          '8': 'Tue',
          '9': 'Wed',
          '10': 'Thu',
          '11': 'Fri',
          '12': 'Sat',
          '13': 'Sun',
          '14': 'Mon',
          '15': 'Tue',
          '16': 'Wed',
          '17': 'Thu',
          '18': 'Fri',
          '19': 'Sat',
          '20': 'Sun',
          '21': 'Mon',
          '22': 'Tue',
          '23': 'Wed',
          '24': 'Thu',
          '25': 'Fri',
          '26': 'Sat',
          '27': 'Sun',
          '28': 'Mon',
          '29': 'Tue',
          '30': 'Wed',
          '31': 'Thu',
        },
        'day': {
          'name': 'GGK',
          'total': 'total',
          '1': 1,
          '2': 2,
          '3': 3,
          '4': 4,
          '5': 5,
          '6': 6,
          '7': 7,
          '8': 8,
          '9': 9,
          '10': 10,
          '11': 11,
          '12': 12,
          '13': 13,
          '14': 14,
          '15': 15,
          '16': 16,
          '17': 17,
          '18': 18,
          '19': 19,
          '20': 20,
          '21': 21,
          '22': 22,
          '23': 23,
          '24': 24,
          '25': 25,
          '26': 26,
          '27': 27,
          '28': 28,
          '29': 29,
          '30': 30,
          '31': 31,
        },
        'Standard': {
          'name': 'Standard',
          'total': 21,
          '1': 1,
          '2': 1,
          '3': 1,
          '4': 1,
          '5': null,
          '6': null,
          '7': 1,
          '8': 1,
          '9': 1,
          '10': 1,
          '11': 1,
          '12': null,
          '13': null,
          '14': 1,
          '15': 1,
          '16': 1,
          '17': 1,
          '18': 1,
          '19': null,
          '20': null,
          '21': 1,
          '22': 1,
          '23': 1,
          '24': 1,
          '25': 1,
          '26': null,
          '27': null,
          '28': 1,
          '29': 1,
          '30': 1,
          '31': 1,
        },
        'Overtime': {
          'name': 'Overtime',
          'total': 0,
          '1': null,
          '2': null,
          '3': null,
          '4': null,
          '5': null,
          '6': null,
          '7': null,
          '8': null,
          '9': null,
          '10': null,
          '11': null,
          '12': null,
          '13': null,
          '14': null,
          '15': null,
          '16': null,
          '17': null,
          '18': null,
          '19': null,
          '20': null,
          '21': null,
          '22': null,
          '23': null,
          '24': null,
          '25': null,
          '26': null,
          '27': null,
          '28': null,
          '29': null,
          '30': null,
          '31': null,
        },
        'AnnualLeave': {
          'name': 'Annual Leave',
          'total': 2,
          '1': null,
          '2': null,
          '3': null,
          '4': null,
          '5': null,
          '6': null,
          '7': null,
          '8': null,
          '9': null,
          '10': null,
          '11': null,
          '12': null,
          '13': null,
          '14': null,
          '15': 1,
          '16': 1,
          '17': null,
          '18': null,
          '19': null,
          '20': null,
          '21': null,
          '22': null,
          '23': null,
          '24': null,
          '25': null,
          '26': null,
          '27': null,
          '28': null,
          '29': null,
          '30': null,
          '31': null,
        },
        'TotalHours': {
          'name': 'Total Hours',
          'total': 23,
          '1': null,
          '2': null,
          '3': null,
          '4': null,
          '5': null,
          '6': null,
          '7': null,
          '8': null,
          '9': null,
          '10': null,
          '11': null,
          '12': null,
          '13': null,
          '14': null,
          '15': 1,
          '16': 1,
          '17': null,
          '18': null,
          '19': null,
          '20': null,
          '21': null,
          '22': null,
          '23': null,
          '24': null,
          '25': null,
          '26': null,
          '27': null,
          '28': null,
          '29': null,
          '30': null,
          '31': null,
        },
      },
      {
        'week': {
          'name': 'Name',
          'total': '',
          '1': 'Tue',
          '2': 'Wed',
          '3': 'Thu',
          '4': 'Fri',
          '5': 'Sat',
          '6': 'Sun',
          '7': 'Mon',
          '8': 'Tue',
          '9': 'Wed',
          '10': 'Thu',
          '11': 'Fri',
          '12': 'Sat',
          '13': 'Sun',
          '14': 'Mon',
          '15': 'Tue',
          '16': 'Wed',
          '17': 'Thu',
          '18': 'Fri',
          '19': 'Sat',
          '20': 'Sun',
          '21': 'Mon',
          '22': 'Tue',
          '23': 'Wed',
          '24': 'Thu',
          '25': 'Fri',
          '26': 'Sat',
          '27': 'Sun',
          '28': 'Mon',
          '29': 'Tue',
          '30': 'Wed',
          '31': 'Thu',
        },
        'day': {
          'name': 'GGK',
          'total': 'total',
          '1': 1,
          '2': 2,
          '3': 3,
          '4': 4,
          '5': 5,
          '6': 6,
          '7': 7,
          '8': 8,
          '9': 9,
          '10': 10,
          '11': 11,
          '12': 12,
          '13': 13,
          '14': 14,
          '15': 15,
          '16': 16,
          '17': 17,
          '18': 18,
          '19': 19,
          '20': 20,
          '21': 21,
          '22': 22,
          '23': 23,
          '24': 24,
          '25': 25,
          '26': 26,
          '27': 27,
          '28': 28,
          '29': 29,
          '30': 30,
          '31': 31,
        },
        'Standard': {
          'name': 'Standard',
          'total': 21,
          '1': 1,
          '2': 1,
          '3': 1,
          '4': 1,
          '5': null,
          '6': null,
          '7': 1,
          '8': 1,
          '9': 1,
          '10': 1,
          '11': 1,
          '12': null,
          '13': null,
          '14': 1,
          '15': 1,
          '16': 1,
          '17': 1,
          '18': 1,
          '19': null,
          '20': null,
          '21': 1,
          '22': 1,
          '23': 1,
          '24': 1,
          '25': 1,
          '26': null,
          '27': null,
          '28': 1,
          '29': 1,
          '30': 1,
          '31': 1,
        },
        'Overtime': {
          'name': 'Overtime',
          'total': 0,
          '1': null,
          '2': null,
          '3': null,
          '4': null,
          '5': null,
          '6': null,
          '7': null,
          '8': null,
          '9': null,
          '10': null,
          '11': null,
          '12': null,
          '13': null,
          '14': null,
          '15': null,
          '16': null,
          '17': null,
          '18': null,
          '19': null,
          '20': null,
          '21': null,
          '22': null,
          '23': null,
          '24': null,
          '25': null,
          '26': null,
          '27': null,
          '28': null,
          '29': null,
          '30': null,
          '31': null,
        },
        'AnnualLeave': {
          'name': 'Annual Leave',
          'total': 2,
          '1': null,
          '2': null,
          '3': null,
          '4': null,
          '5': null,
          '6': null,
          '7': null,
          '8': null,
          '9': null,
          '10': null,
          '11': null,
          '12': null,
          '13': null,
          '14': null,
          '15': 1,
          '16': 1,
          '17': null,
          '18': null,
          '19': null,
          '20': null,
          '21': null,
          '22': null,
          '23': null,
          '24': null,
          '25': null,
          '26': null,
          '27': null,
          '28': null,
          '29': null,
          '30': null,
          '31': null,
        },
        'TotalHours': {
          'name': 'Total Hours',
          'total': 23,
          '1': null,
          '2': null,
          '3': null,
          '4': null,
          '5': null,
          '6': null,
          '7': null,
          '8': null,
          '9': null,
          '10': null,
          '11': null,
          '12': null,
          '13': null,
          '14': null,
          '15': 1,
          '16': 1,
          '17': null,
          '18': null,
          '19': null,
          '20': null,
          '21': null,
          '22': null,
          '23': null,
          '24': null,
          '25': null,
          '26': null,
          '27': null,
          '28': null,
          '29': null,
          '30': null,
          '31': null,
        },
      },
    ];

    const dataSource: any = ref([]);
    json.forEach((item: any) => {
      dataSource.value.push(item.week, item.day, item.Standard, item.Overtime, item.AnnualLeave, item.TotalHours);
    });
    const searchConfigOptions = {};
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    const data_columns = ref([]);
    const refresh = () => {
      loading.value = true;
      loading.value = false;
    };
    refresh();
    const search = () => {
      refresh();
    };
    const baseColumns = computed(() => {
      let list: any = [
        {
          title: 'name',
          dataIndex: 'name',
          minWidth: 80,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '1',
          dataIndex: '1',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '2',
          dataIndex: '2',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '3',
          dataIndex: '3',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '4',
          dataIndex: '4',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '5',
          dataIndex: '5',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '6',
          dataIndex: '6',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '7',
          dataIndex: '7',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '8',
          dataIndex: '8',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '9',
          dataIndex: '9',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '10',
          dataIndex: '10',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '11',
          dataIndex: '11',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '12',
          dataIndex: '12',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '13',
          dataIndex: '13',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '14',
          dataIndex: '14',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '15',
          dataIndex: '15',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '16',
          dataIndex: '16',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '17',
          dataIndex: '17',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '18',
          dataIndex: '18',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '19',
          dataIndex: '19',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '20',
          dataIndex: '20',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '21',
          dataIndex: '21',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '22',
          dataIndex: '22',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '23',
          dataIndex: '23',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '24',
          dataIndex: '24',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '25',
          dataIndex: '25',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '26',
          dataIndex: '26',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '27',
          dataIndex: '27',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '28',
          dataIndex: '28',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '29',
          dataIndex: '29',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '30',
          dataIndex: '25',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: '31',
          dataIndex: '31',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
        {
          title: 'total',
          dataIndex: 'total',
          width: 50,
          autoHeight: true,
          fixed: 'left',
        },
      ];
      data_columns.value.forEach((item: any) => {
        list.push(item);
      });
      return list;
    });
    const dynamicColumnItems = computed(() => {
      let list: any = [];
      baseColumns.value.forEach((item: any) => {
        list.push(
          {
            checked: true,
            fixed: item.fixed ? item.fixed : false,
            key: item.dataIndex,
            label: item.title,
            width: item.width,
          },
        );
      });
      return list;
    });
    const customCell = (obj: any) => {
      if (obj.record.name == 'Name') {
        return { style: { background: '#40556C' } };
      }
      if (obj.record.name == 'GGK') {
        return { style: { background: '#FFB000' } };
      }
      if (obj.record.name == 'Total Hours') {
        return { style: { background: '#D9D9D9' } };
      }
    };
    return {
      searchConfig,
      searchConfigOptions,
      resetFields,
      validateInfos,
      activeKey,
      baseColumns,
      dataSource,
      searchEle,
      tableHeight,
      loading,
      dynamicColumnItems,
      is_export_excel,
      filename,
      search,
      getFullDate,
      customCell,
    };
  },
});
</script>
<style lang="less" scoped>
.cards {
  ::v-deep(.surely-table) {
    font-size: 12px;
  }

  ::v-deep(.surely-table-body) {
    overflow-x: auto;
  }

  ::v-deep(.surely-table-cell) {
    height: 30px !important;
  }

  ::v-deep(.surely-table-cell-box) {
    padding: 0 1px;
  }

  //  table 单元格
  ::v-deep(.surely-table-cell-content) {
    padding: 4px 1px !important;
  }
}
</style>